import { format, subDays, subMonths, subYears } from 'date-fns';

const formatDate = (date: Date) => format(date, 'yyyy-MM-dd');

export const DateTimes = {
  todayDate: formatDate(new Date()),
  yesterdayDate: formatDate(subDays(new Date(), 1)),
  oneWeekAgoDate: formatDate(subDays(new Date(), 7)),
  oneMonthAgoDate: formatDate(subMonths(new Date(), 1)),
  threeMonthAgoDate: formatDate(subMonths(new Date(), 3)),
  oneYearAgoDate: formatDate(subYears(new Date(), 1)),
  allTime: formatDate(subYears(new Date(), 5)),
};

export const SelectionTime = () => {
  const times = [];
  const hours = 24;
  for (let i = 0; i < hours; i++) {
    times.push(`${i < 10 ? '0' + i : i}:00`);
    times.push(`${i < 10 ? '0' + i : i}:15`);
    times.push(`${i < 10 ? '0' + i : i}:30`);
    times.push(`${i < 10 ? '0' + i : i}:45`);
  }
  return times;
};
