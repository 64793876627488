import { isDevelopment } from '@/utility/generic';

const TestProductIds = {
  playlistPitch: {
    priceId: 'price_1OM8lnFLZGJRcZ2mb1Xs376l',
    subscribedPriceId: 'price_1Q16ffFLZGJRcZ2mfSCl9jtX',
  },
  metaAds: {
    priceId: 'price_1OjPBYFLZGJRcZ2mezHKPuv4',
    subscribedPriceId: '',
  },
  tikTokAds: {
    priceId: 'price_1OjPBpFLZGJRcZ2m1y1bR9tw',
    subscribedPriceId: '',
  },
  subscription: {
    priceId: {
      monthly: 'price_1OFGu0FLZGJRcZ2m8XKKgGgw',
      yearly: 'price_1Ong1mFLZGJRcZ2mXYGTNzKW',
    },
  },
};

const ProductionProductIds = {
  playlistPitch: {
    priceId: 'price_1On0XuFLZGJRcZ2mGJff2vHj',
    subscribedPriceId: 'price_1Q16hXFLZGJRcZ2m4KLLVlxw',
  },
  metaAds: {
    priceId: 'price_1On0a9FLZGJRcZ2m2RWlimxQ',
    subscribedPriceId: '',
  },
  tikTokAds: {
    priceId: 'price_1On0apFLZGJRcZ2m32nJVNaa',
    subscribedPriceId: '',
  },
  subscription: {
    priceId: {
      monthly: 'price_1On0XeFLZGJRcZ2m6WSD7rqA',
      yearly: 'price_1Oo09QFLZGJRcZ2mzuNIeuvJ',
    },
  },
};

export const ProductConfig = isDevelopment ? TestProductIds : ProductionProductIds;

export type Products = {
  playlistPitch: SubDiscountPriceModel;
  metaAds: SimplePriceModel;
  tikTokAds: SimplePriceModel;
  subscription: SubscriptionPriceModel;
};

export type SimplePriceModel = {
  priceId: string;
  price: number;
};

export type SubscriptionPriceModel = {
  monthly: SimplePriceModel;
  yearly: SimplePriceModel;
};

export type SubDiscountPriceModel = {
  standard: SimplePriceModel;
  discount: SimplePriceModel;
};
