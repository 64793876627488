export const WebsiteBuilderBackgrounds = {
  images: [
    {
      url: 'https://cdn.unhurd.co.uk/images/website-builder-background-light-blur-rainbow-bubbles.png',
      previewUrl: 'https://cdn.unhurd.co.uk/images/website-builder-background-preview-light-blur-rainbow-bubbles.png',
      type: 'color',
      name: 'light-blur-rainbow-bubbles',
    },
    {
      url: 'https://cdn.unhurd.co.uk/images/website-builder-background-light-blur-pink-shine.png',
      previewUrl: 'https://cdn.unhurd.co.uk/images/website-builder-background-preview-light-blur-pink-shine.png',
      walkThroughUrl:
        'https://cdn.unhurd.co.uk/images/website-builder-background-walkthrough-light-blur-pink-shine.png',
      type: 'color',
      name: 'light-blur-pink-shine',
    },
    {
      url: 'https://cdn.unhurd.co.uk/images/website-builder-background-light-blur-white-geometric.png',
      previewUrl: 'https://cdn.unhurd.co.uk/images/website-builder-background-preview-light-blur-white-geometric.png',
      type: 'color',
      name: 'light-blur-white-geometric',
    },
    {
      url: 'https://cdn.unhurd.co.uk/images/website-builder-background-dark-blur-blue-marbled.png',
      previewUrl: 'https://cdn.unhurd.co.uk/images/website-builder-background-preview-dark-blur-blue-marbled.png',
      type: 'color',
      name: 'dark-blur-blue-marbled',
    },
    {
      url: 'https://cdn.unhurd.co.uk/images/website-builder-background-dark-blur-blue-metallic.png',
      previewUrl: 'https://cdn.unhurd.co.uk/images/website-builder-background-preview-dark-blur-blue-metallic.png',
      walkThroughUrl:
        'https://cdn.unhurd.co.uk/images/website-builder-background-walkthrough-dark-blur-blue-metallic.png',
      type: 'color',
      name: 'dark-blur-blue-metallic',
    },
    {
      url: 'https://cdn.unhurd.co.uk/images/website-builder-background-dark-blur-purple-neon.png',
      previewUrl: 'https://cdn.unhurd.co.uk/images/website-builder-background-preview-dark-blur-purple-neon.png',
      walkThroughUrl:
        'https://cdn.unhurd.co.uk/images/website-builder-background-walkthrough-dark-blur-purple-neon.png',
      type: 'color',
      name: 'dark-blur-purple-neon',
    },
  ],
  colors: [
    {
      hex: '#0E0E0E',
      type: 'color',
      name: 'dark-block-black',
    },
    {
      hex: '#485FCA',
      type: 'color',
      name: 'dark-block-indigo',
    },
    {
      hex: '#1D795D',
      type: 'color',
      name: 'dark-block-forest-green',
    },
    {
      hex: '#FBFAF6',
      type: 'color',
      name: 'light-block-ivory',
    },
    {
      hex: '#F67280',
      type: 'color',
      name: 'light-block-coral',
    },
    {
      hex: '#FFDEEB',
      type: 'color',
      name: 'light-block-pink-pastel',
    },
  ],
};
