export const FeatureFlagNameFormatter = (featureFlagName: string, isEvent?: boolean) => {
  if (import.meta.env.VITE_NODE_ENV === 'development') {
    if (isEvent) {
      return featureFlagName + '_dev';
    } else {
      return featureFlagName + 'Dev';
    }
  } else {
    return featureFlagName;
  }
};

export const FeatureFlagEvents = {
  subscriptionModalViewed: FeatureFlagNameFormatter('subscription_modal_viewed', true),
  initiateCheckout: FeatureFlagNameFormatter('checkout_initiated', true),
};
