import { ToDoListModel } from '@/models/Todo';

export const ToDoCategoryList: ToDoListModel[] = [
  { title: 'Partners', value: 'partners' },
  { title: 'Ads', value: 'ads' },
  { title: 'Promotion', value: 'promotion' },
  { title: 'Artist Services', value: 'artist-services' },
  { title: 'Templates', value: 'templates' },
  { title: 'Tips', value: 'tips' },
];
