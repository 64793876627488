import React from 'react';

import { brandColors } from './BrandColors';
import { ChartOpacity } from './ChartOpacity';

const ChartGradients = () => {
  return (
    <>
      <svg height={1} width={1}>
        <defs>
          {/* INSTAGRAM */}
          <linearGradient id="line-gradient-instagram" x1="0" y1="0" x2="1" y2="0">
            <stop offset="5%" stopColor={brandColors['instagram-color'][0]} stopOpacity={ChartOpacity.full} />
            <stop offset="55%" stopColor={brandColors['instagram-color'][1]} stopOpacity={ChartOpacity.full} />
            <stop offset="85%" stopColor={brandColors['instagram-color'][2]} stopOpacity={ChartOpacity.full} />
          </linearGradient>
          <linearGradient id={`area-gradient-instagram`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={brandColors['instagram-color'][0]} stopOpacity={ChartOpacity.mid} />

            <stop offset="95%" stopColor={brandColors['instagram-color'][1]} stopOpacity={ChartOpacity.none} />
          </linearGradient>

          {/* SPOTIFY */}
          <linearGradient id="line-gradient-spotify" x1="0" y1="0" x2="1" y2="0">
            <stop offset="5%" stopColor={brandColors['spotify-color'][0]} stopOpacity={ChartOpacity.full} />
            <stop offset="85%" stopColor={brandColors['spotify-color'][1]} stopOpacity={ChartOpacity.full} />
          </linearGradient>
          <linearGradient id={`area-gradient-spotify`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={brandColors['spotify-color'][0]} stopOpacity={ChartOpacity.mid} />

            <stop offset="95%" stopColor={brandColors['spotify-color'][1]} stopOpacity={ChartOpacity.none} />
          </linearGradient>

          {/* FACEBOOK */}
          <linearGradient id="line-gradient-facebook" x1="0" y1="0" x2="1" y2="0">
            <stop offset="5%" stopColor={brandColors['facebook-color'][0]} stopOpacity={ChartOpacity.full} />
            <stop offset="85%" stopColor={brandColors['facebook-color'][1]} stopOpacity={ChartOpacity.full} />
          </linearGradient>
          <linearGradient id={`area-gradient-facebook`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={brandColors['facebook-color'][0]} stopOpacity={ChartOpacity.mid} />

            <stop offset="95%" stopColor={brandColors['facebook-color'][1]} stopOpacity={ChartOpacity.none} />
          </linearGradient>

          {/* YOUTUBE */}
          <linearGradient id="line-gradient-youtube" x1="0" y1="0" x2="1" y2="0">
            <stop offset="5%" stopColor={brandColors['youtube-color'][0]} stopOpacity={ChartOpacity.full} />
            <stop offset="85%" stopColor={brandColors['youtube-color'][1]} stopOpacity={ChartOpacity.full} />
          </linearGradient>
          <linearGradient id={`area-gradient-youtube`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={brandColors['youtube-color'][0]} stopOpacity={ChartOpacity.mid} />

            <stop offset="95%" stopColor={brandColors['youtube-color'][1]} stopOpacity={ChartOpacity.none} />
          </linearGradient>

          {/* TIKTOK */}
          <linearGradient id="line-gradient-tiktok" x1="0" y1="0" x2="1" y2="0">
            <stop offset="5%" stopColor={brandColors['tiktok-color'][0]} stopOpacity={ChartOpacity.full} />
            <stop offset="85%" stopColor={brandColors['tiktok-color'][1]} stopOpacity={ChartOpacity.full} />
          </linearGradient>
          <linearGradient id={`area-gradient-tiktok`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={brandColors['tiktok-color'][0]} stopOpacity={ChartOpacity.mid} />

            <stop offset="95%" stopColor={brandColors['tiktok-color'][1]} stopOpacity={ChartOpacity.none} />
          </linearGradient>

          {/* DEEZER */}
          <linearGradient id="line-gradient-deezer" x1="0" y1="0" x2="1" y2="0">
            <stop offset="5%" stopColor={brandColors['deezer-color'][0]} stopOpacity={ChartOpacity.full} />
            <stop offset="20%" stopColor={brandColors['deezer-color'][1]} stopOpacity={ChartOpacity.full} />
            <stop offset="40%" stopColor={brandColors['deezer-color'][2]} stopOpacity={ChartOpacity.full} />
            <stop offset="60%" stopColor={brandColors['deezer-color'][3]} stopOpacity={ChartOpacity.full} />
            <stop offset="95%" stopColor={brandColors['deezer-color'][4]} stopOpacity={ChartOpacity.full} />
          </linearGradient>
          <linearGradient id={`area-gradient-deezer`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={brandColors['deezer-color'][0]} stopOpacity={ChartOpacity.mid} />

            <stop offset="95%" stopColor={brandColors['deezer-color'][1]} stopOpacity={ChartOpacity.none} />
          </linearGradient>

          {/* SOUNDCLOUD */}
          <linearGradient id="line-gradient-soundcloud" x1="0" y1="0" x2="1" y2="0">
            <stop offset="5%" stopColor={brandColors['soundcloud-color'][0]} stopOpacity={ChartOpacity.full} />
            <stop offset="85%" stopColor={brandColors['soundcloud-color'][1]} stopOpacity={ChartOpacity.full} />
          </linearGradient>
          <linearGradient id={`area-gradient-soundcloud`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={brandColors['soundcloud-color'][0]} stopOpacity={ChartOpacity.mid} />

            <stop offset="95%" stopColor={brandColors['soundcloud-color'][1]} stopOpacity={ChartOpacity.none} />
          </linearGradient>

          {/* TWITTER */}
          <linearGradient id="line-gradient-twitter" x1="0" y1="0" x2="1" y2="0">
            <stop offset="5%" stopColor={brandColors['twitter-color'][0]} stopOpacity={ChartOpacity.full} />
            <stop offset="85%" stopColor={brandColors['twitter-color'][1]} stopOpacity={ChartOpacity.full} />
          </linearGradient>
          <linearGradient id={`area-gradient-twitter`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={brandColors['twitter-color'][0]} stopOpacity={ChartOpacity.mid} />

            <stop offset="95%" stopColor={brandColors['twitter-color'][1]} stopOpacity={ChartOpacity.none} />
          </linearGradient>

          {/* DEFAULT */}
          <linearGradient id="line-gradient-default" x1="0" y1="0" x2="1" y2="0">
            <stop offset="5%" stopColor={brandColors['default-color'][0]} stopOpacity={ChartOpacity.full} />
            <stop offset="95%" stopColor={brandColors['default-color'][1]} stopOpacity={ChartOpacity.full} />
          </linearGradient>
          <linearGradient id={`area-gradient-default`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={brandColors['default-color'][0]} stopOpacity={ChartOpacity.mid} />

            <stop offset="95%" stopColor={brandColors['default-color'][1]} stopOpacity={ChartOpacity.none} />
          </linearGradient>

          {/* BLUE GRADIENT */}
          <linearGradient id="line-gradient-blue" x1="0" y1="0" x2="1" y2="0">
            <stop offset="5%" stopColor="#406bff" stopOpacity={ChartOpacity.full} />
            <stop offset="95%" stopColor="#05befe" stopOpacity={ChartOpacity.full} />
          </linearGradient>

          {/* YELLOW GRADIENT */}
          <linearGradient id="line-gradient-yellow" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#ffae18" stopOpacity={ChartOpacity.full} />
            <stop offset="100%" stopColor="#fdd07a" stopOpacity={ChartOpacity.full} />
          </linearGradient>

          {/* PINK GRADIENT */}
          <linearGradient id="line-gradient-pink" x1="0" y1="0" x2="1" y2="0">
            <stop offset="5%" stopColor="#f8005e" stopOpacity={ChartOpacity.full} />
            <stop offset="95%" stopColor="#f8629b" stopOpacity={ChartOpacity.full} />
          </linearGradient>

          {/* PIE GRADIENTS */}
          <linearGradient id="blue-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#406bff" />
            <stop offset="100%" stopColor="#06befe" />
          </linearGradient>
          <linearGradient id="yellow-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#ffae18" />
            <stop offset="100%" stopColor="#fdd07a" />
          </linearGradient>
          <linearGradient id="pink-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#f8005e" />
            <stop offset="100%" stopColor="#f8629b" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default ChartGradients;
