export const navItems = [
  { title: 'NAVIGATION.HOME', name: 'home', icon: 'home', navLink: '/home' },
  {
    title: 'NAVIGATION.CAMPAIGNS',
    name: 'campaigns',
    icon: 'show_chart',
    navLink: '/campaigns',
    notifications: true,
  },
  {
    title: 'NAVIGATION.PROMOTE',
    name: 'promote',
    icon: 'megaphone',
    navLink: '/promote',
  },

  {
    title: 'NAVIGATION.TO-DO-LIST',
    name: 'to-do-list',
    icon: 'list',
    navLink: '/to-do-list',
  },
  {
    title: 'NAVIGATION.DATA-AND-INSIGHTS',
    name: 'insights',
    icon: 'bar_chart',
    navLink: '/insights',
  },
  {
    title: 'NAVIGATION.ACTIVITY',
    name: 'activity',
    icon: 'notifications',
    navLink: '/activity',
  },
  {
    title: 'NAVIGATION.SETTINGS',
    name: 'settings',
    icon: 'settings',
    navLink: '/settings',
  },
];

export const navItemsExpanded = [
  { title: 'NAVIGATION.HOME', name: 'home', icon: 'home', navLink: '/home' },
  {
    title: 'NAVIGATION.CAMPAIGNS',
    name: 'campaigns',
    icon: 'show_chart',
    navLink: '/campaigns',
    notifications: true,
  },
  {
    title: 'NAVIGATION.TO-DO-LIST',
    name: 'to-do-list',
    icon: 'list',
    navLink: '/to-do-list',
  },

  {
    title: 'NAVIGATION.DATA-AND-INSIGHTS',
    name: 'insights',
    icon: 'bar_chart',
    navLink: '/insights',
  },
  {
    title: 'NAVIGATION.PROMOTE',
    name: 'promote',
    icon: 'megaphone',
    navLink: '/promote',
  },
  {
    title: 'NAVIGATION.ACTIVITY',
    name: 'activity',
    icon: 'notifications',
    navLink: '/activity',
  },
  {
    title: 'NAVIGATION.PROFILE',
    name: 'profile',
    icon: 'person',
    navLink: '/profile',
  },
  {
    title: 'NAVIGATION.SETTINGS',
    name: 'settings',
    icon: 'settings',
    navLink: '/settings',
  },
];
