import { useNavigate } from 'react-router-dom';

import { validateEmail } from '@/formatters/EmailValidator';
import { TodoAccountDataModel } from '@/models/Todo';

export const useToDoActionHandler = (todo: TodoAccountDataModel) => {
  const navigate = useNavigate();

  const handlePromote = () => {
    if (todo.content.button.text === 'Join Pro') {
      navigate('/home?subscription=true');
    } else if (todo.content.button.url) {
      if (validateEmail(todo.content.button.url)) {
        return window.open(`mailto:${todo.content.button.url}?subject=${todo.content.title}`);
      } else {
        return window.open(todo.content.button.url);
      }
    } else if (todo.category === 'promotion') {
      switch (todo.type) {
        case 'build-fan-hub': {
          navigate('/promote?promoteState=website');
          return;
        }
        case 'pitch-spotify-playlists': {
          navigate('/promote?promoteState=playlisting');
          return;
        }
      }
    } else if (todo.category === 'ads') {
      switch (todo.type) {
        case 'boost-views-tiktok-video': {
          navigate('/promote?promoteState=tiktok&todoGoal=tiktok-followers');
          break;
        }
        case 'grow-spotify-streams': {
          navigate('/promote?promoteState=meta&todoGoal=more-streams');
          break;
        }
        case 'grow-amazon-music-streams': {
          navigate('/promote?promoteState=meta&todoGoal=more-streams');
          break;
        }
        case 'grow-apple-music-streams': {
          navigate('/promote?promoteState=meta&todoGoal=more-streams');
          break;
        }
        case 'grow-youtube-music-streams': {
          navigate('/promote?promoteState=meta&todoGoal=youtube-views');
          break;
        }
        case 'increase-facebook-followers': {
          navigate('/promote?promoteState=meta&todoGoal=facebook-likes');
          break;
        }
        case 'increase-instagram-followers': {
          navigate('/promote?promoteState=meta&todoGoal=instagram-followers');
          break;
        }
        case 'increase-tiktok-followers': {
          navigate('/promote?promoteState=tiktok&todoGoal=tiktok-followers');
          break;
        }
        case 'increase-spotify-followers': {
          navigate('/promote?promoteState=meta&todoGoal=spotify-followers');
          break;
        }
      }
    } else {
      navigate('/promote');
    }
  };

  return {
    handleToDo: handlePromote,
  };
};
