import { AdvertCurrencyModel, AdvertGenders } from '@/models/Adverts';
import { TikTokAdsAges } from '@/models/TikTok';

export const tikTokGoals = [
  {
    icon: 'music',
    value: 'more-streams',
    title: 'SOCIAL-ADS.GOALS.MORE-STREAMS',
    description: 'SOCIAL-ADS.GOALS.MORE-STREAMS-DESCRIPTION',
  },
  {
    icon: 'spotify',
    value: 'spotify-followers',
    title: 'SOCIAL-ADS.GOALS.SPOTIFY-GROWTH',
    description: 'SOCIAL-ADS.GOALS.SPOTIFY-GROWTH-DESCRIPTION',
  },
  {
    icon: 'tiktok',
    value: 'tiktok-followers',
    title: 'SOCIAL-ADS.GOALS.TIKTOK-GROWTH',
    description: 'SOCIAL-ADS.GOALS.TIKTOK-GROWTH-DESCRIPTION',
  },
  {
    icon: 'youtube',
    value: 'youtube-views',
    title: 'SOCIAL-ADS.GOALS.YOUTUBE-GROWTH',
    description: 'SOCIAL-ADS.GOALS.YOUTUBE-GROWTH-DESCRIPTION',
  },
  {
    icon: 'chart',
    value: 'link-clicks',
    title: 'SOCIAL-ADS.GOALS.LINK-CLICKS',
    description: 'SOCIAL-ADS.GOALS.LINK-CLICKS-DESCRIPTION',
  },
  {
    icon: 'headphones',
    value: 'promote-event',
    title: 'SOCIAL-ADS.GOALS.PROMOTE-AN-EVENT',
    description: 'SOCIAL-ADS.GOALS.PROMOTE-AN-EVENT-DESCRIPTION',
  },
  {
    icon: 'clothes',
    value: 'sell-merch',
    title: 'SOCIAL-ADS.GOALS.SELL-YOUR-MERCH',
    description: 'SOCIAL-ADS.GOALS.SELL-YOUR-MERCH-DESCRIPTION',
  },
];

export const tikTokGoalPlatforms = [{ logo: 'tiktok', name: 'TikTok' }];

export const tikTokCurrencyCalculations = (currency?: AdvertCurrencyModel) => {
  const baseDailyBudgetValue = currency?.baseDailyBudgetValue ?? 0;
  const minDailySpendRatio = currency?.minDailySpendRatio ?? 0;
  const dailySpend = baseDailyBudgetValue * minDailySpendRatio || 5;
  return {
    dailySpend: dailySpend,
    startPrice: dailySpend * 100,
    minSpend: dailySpend * 3,
    maxSpend: dailySpend * 200,
  };
};

export const tikTokAgeRangesString: Record<string, string> = {
  AGE_18_24: '18-24',
  AGE_25_34: '25-34',
  AGE_35_44: '35-44',
  AGE_45_54: '45-54',
  AGE_55_100: '55+',
};

export const tikTokAgeRanges: { value: TikTokAdsAges; viewValue: string }[] = [
  { value: 'AGE_18_24', viewValue: '18-24' },
  { value: 'AGE_25_34', viewValue: '25-34' },
  { value: 'AGE_35_44', viewValue: '35-44' },
  { value: 'AGE_45_54', viewValue: '45-54' },
  { value: 'AGE_55_100', viewValue: '55+' },
];

export const metaGoals = [
  {
    icon: 'music',
    value: 'more-streams',
    title: 'SOCIAL-ADS.GOALS.MORE-STREAMS',
    description: 'SOCIAL-ADS.GOALS.MORE-STREAMS-DESCRIPTION',
  },
  {
    icon: 'spotify',
    value: 'spotify-followers',
    title: 'SOCIAL-ADS.GOALS.SPOTIFY-GROWTH',
    description: 'SOCIAL-ADS.GOALS.SPOTIFY-GROWTH-DESCRIPTION',
  },
  {
    icon: 'instagram',
    value: 'instagram-followers',
    title: 'SOCIAL-ADS.GOALS.INSTAGRAM-GROWTH',
    description: 'SOCIAL-ADS.GOALS.INSTAGRAM-GROWTH-DESCRIPTION',
  },
  {
    icon: 'facebook',
    value: 'facebook-likes',
    title: 'SOCIAL-ADS.GOALS.FACEBOOK-GROWTH',
    description: 'SOCIAL-ADS.GOALS.FACEBOOK-GROWTH-DESCRIPTION',
  },
  {
    icon: 'youtube',
    value: 'youtube-views',
    title: 'SOCIAL-ADS.GOALS.YOUTUBE-GROWTH',
    description: 'SOCIAL-ADS.GOALS.YOUTUBE-GROWTH-DESCRIPTION',
  },
  {
    icon: 'chart',
    value: 'link-clicks',
    title: 'SOCIAL-ADS.GOALS.LINK-CLICKS',
    description: 'SOCIAL-ADS.GOALS.LINK-CLICKS-DESCRIPTION',
  },
  {
    icon: 'headphones',
    value: 'promote-event',
    title: 'SOCIAL-ADS.GOALS.PROMOTE-AN-EVENT',
    description: 'SOCIAL-ADS.GOALS.PROMOTE-AN-EVENT-DESCRIPTION',
  },
  {
    icon: 'clothes',
    value: 'sell-merch',
    title: 'SOCIAL-ADS.GOALS.SELL-YOUR-MERCH',
    description: 'SOCIAL-ADS.GOALS.SELL-YOUR-MERCH-DESCRIPTION',
  },
];

export const metaGoalPlatforms = [
  { logo: 'facebook', name: 'Facebook' },
  { logo: 'instagram', name: 'Instagram' },
];

export const metaCurrencyCalculations = (currency?: AdvertCurrencyModel) => {
  const baseDailyBudgetValue = currency?.baseDailyBudgetValue ?? 0;
  const minDailySpendRatio = currency?.minDailySpendRatio ?? 0;
  const dailySpend = baseDailyBudgetValue * minDailySpendRatio || 5;
  return {
    dailySpend: dailySpend,
    startPrice: dailySpend * 100,
    minSpend: dailySpend * 2,
    maxSpend: dailySpend * 200,
  };
};

export const advertGenders: { value: AdvertGenders; viewValue: string; icon: string }[] = [
  {
    value: 'GENDER_UNLIMITED',
    viewValue: 'COMMON.ALL',
    icon: 'all',
  },
  { value: 'GENDER_MALE', viewValue: 'COMMON.MALE', icon: 'male' },
  {
    value: 'GENDER_FEMALE',
    viewValue: 'COMMON.FEMALE',
    icon: 'female',
  },
];

export const genderMap: { [key: number]: string } = {
  0: 'GENDER_UNLIMITED',
  1: 'GENDER_MALE',
  2: 'GENDER_FEMALE',
};

export const inverseGenderMap: { [key: string]: number } = Object.entries(genderMap).reduce(
  (acc, [key, value]) => {
    acc[value] = Number(key);
    return acc;
  },
  {} as { [key: string]: number }
);
