export const brandColors: Record<string, string[]> = {
  'spotify-color': ['#1ed760', '#1ed760'],
  'youtube-color': ['#FE3D2F', '#FE3D2F'],
  'instagram-color': ['#FB9021', '#E14567', '#9239A7'],
  'tiktok-color': ['#FE2C55', '#FE2C55'],
  'facebook-color': ['#1877F2', '#1877F2'],
  'deezer-color': ['#F0991F', '#D53B54', '#922289', '#254AB9', '#0D9F7E'],
  'soundcloud-color': ['#FF5500', '#FF5500'],
  'twitter-color': ['#FFFFFF', '#FFFFFF'],
  'default-color': ['#406bff', '#05befe'],
};

export const brandColorsAreaFaded: Record<string, string[]> = {
  'spotify-color': ['#1ed76050', '#1ed760'],
  'youtube-color': ['#FE3D2F50', '#FE3D2F'],
  'instagram-color': ['#FB902150', '#E14567', '#9239A7'],
  'tiktok-color': ['#FE2C5550', '#FE2C55'],
  'facebook-color': ['#1877F250', '#1877F2'],
  'deezer-color': ['#F0991F50', '#D53B54', '#922289', '#254AB9', '#0D9F7E'],
  'soundcloud-color': ['#FF550050', '#FF5500'],
  'twitter-color': ['#FFFFFF50', '#FFFFFF'],
  'default-color': ['#406bff50', '#05befe'],
};

export const brandColorsSingle = {
  spotify: '#1ed760',
  youtube: '#FE3D2F',
  instagram: '#f54e86',
  tiktok: '#FE2C55',
  facebook: '#1877F2',
  deezer: '#29ab70',
  soundcloud: '#FF5500',
  twitter: '#FFFFFF',
};

export const chartColors = {
  barChart: {
    blueOne: '#05BEFE',
    blueTwo: '#0A779C',
  },
  pieChart: {
    blue: '#05BEFE',
    pink: '#f8629b',
    platforms: {
      instagram: '#d741e4',
      facebook: '#406dff',
      tiktok: '#f9146b',
      twitter: '#ffffff',
      spotify: '#1baa65',
      amazon: '#ffd028',
      soundcloud: '#ff8022',
      youtube_channel: '#f9146b',
      deezer: '#b041e4',
      apple: '#ffffff',
    },
  },
};
